<template>
    <div>
        <van-nav-bar title="整改记录" left-text="返回" left-arrow @click-left="$router.go(-1)" />
        
        <van-cell title="类型" :value="types.val" @click="types.show = true" is-link v-if="show" />
        <van-popup v-model="types.show" round position="bottom">
        <van-picker :columns="types.lis" show-toolbar @cancel="types.show = false" @confirm="typesSel" value-key="NAME" />
        </van-popup>
        <van-cell title="状态" :value="status.val" @click="status.show = true" is-link v-if="show" />
        <van-popup v-model="status.show" round position="bottom">
        <van-picker :columns="status.lis" show-toolbar @cancel="status.show = false" @confirm="statusSel" value-key="NAME" />
        </van-popup>

        <van-empty description="暂无数据" v-if="grid.ls.length == 0" />
        <div v-else>
            <van-list :finished="grid.finish" @load="loadPage" v-model="grid.loading" :immediate-check="false"
                style="padding: 10px 0px">
                <div class="wcard" @click="edit(item)" v-for="item in grid.ls" :key="item.ID">
                    <div class="bd">
                        <van-row :gutter="8">
                            <van-col :span="24">
                                <p style="display: flex;">
                                    检查类型：{{ item.TYPE_NAME }}
                                    <span class="sta_sty" :class="'sta'+item.STATUS">{{item.STATUS_TEXT}}</span>
                                </p>
                                <div style="font-size: 14px; color: #696868">
                                    <p>位置：{{ item.LOCATION }}</p>
                                    <p>存在问题：{{ item.PROBLEM }}</p>
                                </div>

                                <p><van-image v-for="(item, index) in item.IMAGELIST" :key="index" :src="item.Value"
                                        style="width: 50px;height: 50px;  margin-top: 8px;margin-right: 5px"
                                        fit="cover" />

                                </p>
                            </van-col>
                        </van-row>
                    </div>
                    <div class="ft">
                        <van-row>
                            <van-col :span="12">检查人：{{ item.CREATED_NAME }}</van-col>
                            <van-col :span="12" style="text-align: right">检查日期：{{ item.CREATED_DATE }}</van-col>
                        </van-row>
                    </div>
                </div>
            </van-list>
        </div>
    </div>
</template>
<script>
    export default {
        name: "index",
        data() {
            return {
                grid: {
                    sea: {
                        PAGE_SIZE: 10,
                        PAGE_INDEX: -1,
                        STATUS:1,
                        TYPE_ID:0
                    },
                    ls: [],
                    total: 0,
                    loading: false,
                    finish: false,
                },
                types:{
                    show: false,
                    val: '全部',
                    lis: []
                },
                status: {
                    show: false,
                    val: '待整改',
                    lis: [{ NAME: '全部', KEY: 0 }, { NAME: '待整改', KEY: 1 }, { NAME: '已整改', KEY: 2 }]
                },
                show: true
            };
        },
        mounted() {
            // if (this.$route.query.for == "SUB") {
            //     document.title = "我的记录";
            // }
        },
        created() {
            this.filter();
            this.getTypes();
        },
        methods: {
            getTypes() {
                let self = this;
                this.whale.remote.getResult({
                    url: "/api/Mobile/INS/RecordApi/GetTypes",
                    completed: function (res) {
                        self.types.lis = res.DATA.map(x => ({ KEY: x.ID, NAME: x.NAME }))
                        self.types.lis.unshift({ KEY: 0, NAME: '全部' })
                        // console.log(self.options)
                    },
                });
            },
            filter() {
                this.grid.sea.PAGE_INDEX = 0;
                this.grid.ls = [];
                this.grid.finish = false;
                this.getList();
            },
            loadPage() {
                this.grid.sea.PAGE_INDEX += 1;
                this.getList();
            },
            statusSel(e) {
                console.log(e)
                this.status.val = e.NAME
                this.grid.sea.STATUS = e.KEY
                this.status.show = false
                this.filter();
            },
            typesSel(e){
                console.log(e)
                this.types.val = e.NAME
                this.grid.sea.TYPE_ID = e.KEY
                this.types.show = false
                this.filter();
            },
            getList() {
                let self = this;
                this.grid.loading = true;
                this.whale.remote.getCollection({
                    url: "/api/Mobile/INS/RecordApi/GetRectifys",
                    data: this.grid.sea,
                    finally() {
                        self.grid.loading = false;
                    },
                    completed: function (its, n) {
                        self.grid.ls.push(...its.ITEMS);
                        if (self.grid.ls.length >= n) self.grid.finish = true;
                    },
                });
            },
            edit(item) {
                if (item.STATUS == 1)
                    this.$router.push({ path: "/ins/rectify/edit", query: { id: item.ID } });
                else
                    this.$router.push({ path: "/ins/rectify/detail", query: { id: item.ID } });
            },

        },
    };
</script>

<style scoped>
    .wcard {
        margin: 0 12px 12px;
        overflow: hidden;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    }

    .wcard .ft {
        border-top: 1px dashed #ebedf0;
        padding: 8px 16px;
        font-size: 12px;
        min-height: 20px;
    }

    .wcard .ft .ov {
        color: #ee0a24;
        font-weight: bold;
    }

    .wcard .bd {
        padding: 0px 15px;
    }

    .wcard .bd .lf {
        text-align: center;
        color: #ee0a24;
        padding-top: 10px;
    }

    .wcard .bd .lf h2 {
        font-size: 30px;
        font-weight: 500;
        display: inline;
    }

    .wcard .bd .lf span {
        font-size: 40%;
        font-weight: normal;
        margin-left: 2px;
    }

    .wcard .bd .lf p {
        font-size: 14px;
        line-height: 16px;
    }

    .sta_sty {
        margin-left: auto;
        /* color: #3880ec; */
        font-size: 16px;
    }

    .sta0 {
        color: #a8aaac;
    }

    .sta1 {
        color: #f06e6e;
    }

    .sta2 {
        color: #668fe9;
    }
</style>